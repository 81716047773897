@font-face {
  font-display: swap;
  font-family: 'Rooney-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/RooneyRegular/font.woff2') format('woff2'),
    url('./fonts/RooneyRegular/font.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Rooney-Bold';
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/RooneyBold/font.woff2') format('woff2'),
    url('./fonts/RooneyBold/font.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Rooney-BoldItalic';
  font-style: italic;
  font-weight: bold;
  src: url('./fonts/RooneyBoldItalic/font.woff2') format('woff2'),
    url('./fonts/RooneyBoldItalic/font.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Rooney-RegularItalic';
  font-style: italic;
  font-weight: normal;
  src: url('./fonts/RooneyRegularItalic/font.woff2') format('woff2'),
    url('./fonts/RooneyRegularItalic/font.woff') format('woff');
}

em {
  font-family: 'Rooney-RegularItalic';
  font-style: normal;
  font-weight: normal;
}

strong {
  font-family: 'Rooney-Bold';
  font-style: normal;
  font-weight: normal;
}

h1 {
  font-size: 3.75rem;
  line-height: 1.0667;
}

a {
  color: theme('colors.cerulean');
}

p {
  padding-bottom: theme('padding.5');
}

section.title {
  padding-top: 383px;
}

.capitol-background {
  background-image: url('./images/us-capitol.svg');
  background-size: 674px 275px;
  height: 275px;
  top: 18px;
}

.header-background-banner {
  height: 290px;
}

.page-background {
  background: linear-gradient(
    to bottom,
    #002c63 0,
    #002c63 290px,
    #f9f9f9 290px,
    #f9f9f9 100%
  );
}

@screen md {
  h1 {
    font-size: 5.25rem;
    line-height: 1.0357;
  }

  section.title {
    padding-top: 490px;
  }

  .capitol-background {
    background-size: 902px 368px;
    height: 368px;
  }

  .header-background-banner {
    height: 380px;
  }
}
